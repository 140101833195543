/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import parse from "./_parse";

require("dotenv").config();

export async function getAutoSchedule(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_autoscheduleresult",
      param
    );
    return lists;
  } catch (error) {
    console.log("getAutoSchedule() = ", JSON.stringify(error));
    return error;
  }
}

export async function getAutoScheduleConfig(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_latest_autoscheduleresult_config ",
      param
    );
    return lists;
  } catch (error) {
    console.log("getAutoScheduleConfig() = ", JSON.stringify(error));
    return error;
  }
}

export async function getEntityPrograms() {
  try {
    const lists = await parse.Cloud.run("v2_webapp_get_entity_programs");
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getEntityPrograms() = ", JSON.stringify(error));
    return error;
  }
}

export async function getDispatchBoardTemplateList() {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_dispatch_board_template_list"
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getDispatchBoardTemplateList() = ", JSON.stringify(error));
    return error;
  }
}

export async function getDispatchBoardTemplate() {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_get_dispatch_board_template"
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getDispatchBoardTemplate() = ", JSON.stringify(error));
    return error;
  }
}

export async function getDispatchBookingList(param) {
  try {
    const lists = await parse.Cloud.run(
      "v2_webapp_get_dispatch_booking_list",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getDispatchBookingList() = ", JSON.stringify(error));
    return error;
  }
}

export async function applyDispatchBoardTemplate(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_apply_dispatch_board_template",
      param
    );
    // console.log(lists);
    return data;
  } catch (error) {
    console.log("applyDispatchBoardTemplate() = ", JSON.stringify(error));
    return error;
  }
}

export async function createTemplateFlexTrips(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_create_template_flex_trips",
      param
    );
    // console.log(lists);
    return data;
  } catch (error) {
    console.log("createTemplateFlexTrips() = ", JSON.stringify(error));
    return error;
  }
}

export async function editDispatchBoardTemplate(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_edit_dispatch_board_template",
      param
    );
    // console.log(lists);
    return data;
  } catch (error) {
    console.log("editDispatchBoardTemplate() = ", JSON.stringify(error));
    return error;
  }
}

export async function createDispatchBoardTemplate(param) {
  try {
    const data = await parse.Cloud.run(
      "v1_webapp_create_dispatch_board_template",
      param
    );
    // console.log(lists);
    return data;
  } catch (error) {
    console.log("createDispatchBoardTemplate() = ", JSON.stringify(error));
    return error;
  }
}

export async function getAssignedVehicleTripList(param) {
  try {
    const lists = await parse.Cloud.run(
      "v2_webapp_get_assigned_vehicle_trip_list",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getAssignedVehicleTripList() = ", JSON.stringify(error));
    return error;
  }
}

export async function getManualDispatchProgramShuttles(param) {
  try {
    const lists = await parse.Cloud.run(
      "v2_webapp_get_manual_dispatch_program_shuttles",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getManualDispatchProgramShuttles() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFleetDriverList() {
  try {
    const lists = await parse.Cloud.run("v2_webapp_get_fleet_driver_list");
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("getFleetDriverList() = ", JSON.stringify(error));
    return error;
  }
}

export async function createNewSingleFlexTrip(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_create_new_single_flex_trip",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("createNewFlexTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function createNewDriverTeam(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_create_new_driver_team",
      param
    );
    return lists;
  } catch (error) {
    console.log("createNewFlexTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function createMultipleFlexTrips(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_create_new_multiple_flex_trips",
      param
    );
    return lists;
  } catch (error) {
    console.log("createMultipleFlexTrips() = ", JSON.stringify(error));
    return error;
  }
}

export async function createCallTimer(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_save_willcall_call_time",
      param
    );
    return lists;
  } catch (error) {
    console.log("createNewFlexTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function getFleetDriverTeam() {
  try {
    const lists = await parse.Cloud.run("v1_webapp_get_fleet_driver_team_list");
    return lists;
  } catch (error) {
    console.log("createNewFlexTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function createNewFlexTrip(param) {
  try {
    const lists = await parse.Cloud.run(
      "v2_webapp_create_new_flex_trip",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("createNewFlexTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function updateDispatchTrip(param) {
  try {
    const lists = await parse.Cloud.run(
      "v2_webapp_update_dispatch_trip",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("updateDispatchBooking() = ", JSON.stringify(error));
    return error;
  }
}

export async function removeScheduledTrip(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_remove_scheduled_trip",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("removeScheduledTrip() = ", JSON.stringify(error));
    return error;
  }
}

export async function updateBookingTravelData(param) {
  try {
    const lists = await parse.Cloud.run(
      "v1_webapp_update_booking_travel_data",
      param
    );
    // console.log(lists);
    return lists;
  } catch (error) {
    console.log("updateBookingTravelData() = ", JSON.stringify(error));
    return error;
  }
}

export async function updateSingleBookingTrip(param) {
  try {
    const response = await parse.Cloud.run(
      "v1_webapp_update_single_booking_trip",
      param
    );
    return response;
  } catch (error) {
    console.log("Service bookings::updateSingleBookingTrip = ", error);
    return error;
  }
}

export async function getDispatchBoardTemplateInfoById(param) {
  try {
    const response = await parse.Cloud.run(
      // "v1_webapp_get_dispatch_template_info_by_id",
      "v1_webapp_get_dispatch_board_template_info_by_id",
      param
    );
    return response;
  } catch (error) {
    console.log("Service bookings::getDispatchBoardTemplateInfoById = ", error);
    return error;
  }
}
